<template>
    <v-dialog
        v-model="show"
        max-width="700px"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="light-blue lighten-4"
                class="text-h6"
            >   {{dialogTitle}}
                <v-spacer />
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                
            </v-toolbar>
            <v-card-text style="overflow-y: auto; height: 500px;">

                
                <v-container>
                    <v-row v-for="(error, index) in userConsolidationErrors" :key="index">
                        <v-col class="d-flex align-center text-h6">
                            {{formatDate(error.workDate)}}
                        </v-col>
                        <v-col>
                            <v-sheet 
                                v-if="error.errorCode == 'REQUIRED_TIMES_NOT_COVERED'" 
                                rounded="pill" 
                                color="red lighten-3" 
                                class="d-flex flex-row justify-space-between py-2 px-4 align-center clickable"
                                v-on:click="openErrorResolveDialog(index)"
                            >
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-clock
                                </v-icon>
                                <v-spacer />
                                <div class="font-weight-medium">
                                    Orario Pianificato non coperto
                                </div>
                                <v-spacer />
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-chevron-right
                                </v-icon>
                               
                            </v-sheet>
                            <v-sheet 
                                v-if="error.errorCode == 'UNNECESSARY_ABSENCE_TIMES'" 
                                rounded="pill" 
                                color="light-blue lighten-3" 
                                class="d-flex flex-row justify-space-between py-2 px-4 align-center clickable"
                                v-on:click="openErrorResolveDialog(index)"
                            >
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-umbrella-beach
                                </v-icon>
                                <v-spacer />
                                <div class="font-weight-medium">
                                    Minuti di Assenza non necessari
                                </div>
                                <v-spacer />
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-chevron-right
                                </v-icon>
                               
                            </v-sheet>
                            <v-sheet 
                                v-if="error.errorCode == 'PENDING_VARIATION_PROPOSAL'" 
                                rounded="pill" 
                                color="orange lighten-3" 
                                class="d-flex flex-row justify-space-between py-2 px-4 align-center clickable"
                                v-on:click="goToVariationProposal(error.workDate)"
                            >
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-octagram
                                </v-icon>
                                <v-spacer />
                                <div class="font-weight-medium">
                                    Proposta di Modifica in attesa
                                </div>
                                <v-spacer />
                                <v-icon
                                    class="my-2"
                                >
                                    mdi-chevron-right
                                </v-icon>
                            </v-sheet>
                            
                        </v-col>
                        <!-- <v-col>
                            <v-container class="d-flex flex-column align-center">
                                        <span v-if="error.userWorkDay != null && error.userWorkDay.variationProposal != null">
                                            <v-sheet color="orange lighten-2" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                                <v-icon
                                                    class="mr-2"
                                                >
                                                    mdi-octagram
                                                </v-icon>
                                                <v-spacer />
                                                <div class="font-weight-medium">
                                                    Proposta di Modifica in attesa
                                                </div>
                                                <v-spacer />
                                            </v-sheet>
                                        </span>
                                        <span v-else>
                                            <span v-if="error.userWorkDay == null || error.userWorkDay.workDayRecords.length == 0" class="font-italic">
                                                Nessun Record
                                            </span>
                                            <span v-else v-for="(wdr, index) in error.userWorkDay.workDayRecords" :key="index">
                                                <v-sheet color="grey lighten-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='CLOCK_RECORD'">
                                                    <v-icon left color="black">
                                                        mdi-stamper
                                                    </v-icon>
                                                    <v-spacer />
                                                    <div class="font-weight-bold">
                                                        {{formatTimeString(wdr.clockRecord.punchInTime)}}&nbsp;&nbsp;&nbsp;{{formatTimeString(wdr.clockRecord.punchOutTime)}}
                                                    </div>
                                                    <v-spacer />
                                                </v-sheet>
                                                <v-sheet color="teal accent-1" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='LEAVE_RECORD'">
                                                    <v-icon left color="black">
                                                        mdi-umbrella-beach-outline
                                                    </v-icon>
                                                    <v-spacer />
                                                    <div class="font-weight-bold">
                                                        {{formatDurationString(wdr.leaveRecord.minuteDuration)}}
                                                    </div>
                                                    <v-spacer />
                                                </v-sheet>
                                                <v-sheet color="cyan accent-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='HOLIDAY_RECORD'">
                                                    <v-icon left color="black">
                                                        mdi-umbrella-beach-outline
                                                    </v-icon>
                                                    <v-spacer />
                                                    <div class="font-weight-bold">
                                                        {{formatDurationString(wdr.holidayRecord.minuteDuration)}}
                                                    </div>
                                                    <v-spacer />
                                                </v-sheet>
                                                <v-sheet color="pink lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2" v-if="wdr.recordType=='SICK_RECORD'">
                                                    <v-icon left color="black">
                                                        mdi-emoticon-sick
                                                    </v-icon>
                                                    <v-spacer />
                                                    <div class="font-weight-bold">
                                                        Malattia
                                                    </div>
                                                    <v-spacer />
                                                </v-sheet>
                                            </span>
                                        </span>
                                        <span
                                            v-if="error.userWorkDay && error.userWorkDay.overtimeMinutes > 0"
                                        >
                                            <v-sheet color="purple lighten-3" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                                <v-icon left color="black">
                                                    mdi-alarm-plus
                                                </v-icon>
                                                <v-spacer />
                                                <div class="font-weight-bold" style="white-space: normal !important;">
                                                    {{formatDurationString(error.userWorkDay.overtimeMinutes)}}
                                                </div>
                                                <v-spacer />
                                            </v-sheet>
                                        </span>
                                        <span
                                            v-if="error.userWorkDay && error.userWorkDay.businessTrip"
                                        >
                                            <v-sheet color="amber accent-4" rounded="pill" width="180" class="d-flex flex-row justify-space-between py-2 px-4 align-center mb-2">
                                                <v-icon left color="black">
                                                    mdi-bag-suitcase
                                                </v-icon>
                                                <v-spacer />
                                                <div class="font-weight-bold">
                                                    Trasferta
                                                </div>
                                                <v-spacer />
                                            </v-sheet>
                                        </span>
                                    </v-container>
                        </v-col> -->
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        userErrors: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    methods: {
        cancelMethod: function() {
            // this.$refs.form.reset();
            this.$emit('close-user-consolidation-errors-dialog');
        },
        formatDate: function(isoDateString) {
            return DateTime.fromISO(isoDateString).setLocale('it').toFormat("cccc d");
        },
        formatTimeString: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else {
                return "--:--";
            }
        },
        formatDurationString: function(minutesDuration) {
            return (minutesDuration >= 60 ? Math.floor(minutesDuration/60) + 'h ' : '') + (minutesDuration % 60 != 0 ? minutesDuration % 60 + 'm': '').trim();
        },
        openErrorResolveDialog: function(error) {
            this.$emit('open-error-resolve-dialog', error);
        },
        goToVariationProposal: function(workDate) {
            let username = this.userData.username;
            this.$router.push({ name: 'VariationProposals', params: { username: username, date: workDate } });
        }
    },
    watch: {
        
    },
    computed: {
        userConsolidationErrors: function() {
            return ((this.userErrors || {}).consolidationErrors || []);
        },
        formattedConsolidationPeriod: function() {
            let workDate = (this.userConsolidationErrors[0] || {}).workDate;
            if(workDate)
                return DateTime.fromISO(workDate).setLocale('it').toFormat("LLLL y");
            else 
                return '';
        },
        userData: function() {
            return ((this.userErrors || {}).user || {});
        },
        dialogTitle: function() {
            let user = this.userData;
            return "Errori di " + user.lastName + " " + user.firstName + " - " + this.formattedConsolidationPeriod;
        }
    } 
}
</script>
<style>
    html {
        overflow: hidden !important;
    }
    .clickable :hover {
        cursor: pointer;
    }
</style>
